<template>
  <v-app>
    <v-container fluid style="background-color: white">
      <h1 v-if="selectedFood">
        {{ selectedFood.name }},

        <span v-if="ingredients">{{ ingredients.length }}</span>
      </h1>
      <p v-if="esisProducts">{{ esisProducts.length }}</p>
      <v-row class="mx-10">
        <v-col cols="12">
          <v-tabs v-model="selectedTab">
            <v-tab> Xоолны бүрдэл </v-tab>
            <v-tab> Теxнологи ажиллагаа </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab">
            <v-tab-item>
              <v-card>
                <v-row class="px-10 py-10">
                  <v-col cols="6">
                    <v-row justify="end">
                      <v-btn
                        class="mr-10"
                        @click="_removePurchases"
                        v-if="purchases && purchases.length > 0"
                        >Арилгаx</v-btn
                      >

                      <v-btn
                        class="bg-danger bg-gradiant"
                        dark
                        v-if="purchases && purchases.length > 0"
                        >Батлаx</v-btn
                      >
                    </v-row>
                  </v-col>
                </v-row>
                <v-btn
                  @click="_addNewFoodIntoSet()"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
                  >Бүрэлдэxүүн+</v-btn
                >
                <v-data-table
                  style="height: 500px; overflow-y: auto"
                  v-if="selectedFood && ingredients"
                  class="elevation-1"
                  :items="ingredients"
                  :headers="ingredientsHeaders"
                >
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td @click="_print(props.item)">
                        {{ props.item.index }}
                      </td>
                      <td>{{ props.item.name }}</td>

                      <td style="background-color: #ececb2">
                        {{ props.item.kkal1 }}
                      </td>
                      <td style="background-color: #ececb2">
                        {{ props.item.nuursus1 }}
                      </td>
                      <td style="background-color: #ececb2">
                        {{ props.item.uurag1 }}
                      </td>
                      <td style="background-color: #ececb2">
                        {{ props.item.uuhtos1 }}
                      </td>

                      <td style="background-color: #d6fbd6">
                        {{ props.item.kkal2 }}
                      </td>
                      <td style="background-color: #d6fbd6">
                        {{ props.item.nuursus2 }}
                      </td>
                      <td style="background-color: #d6fbd6">
                        {{ props.item.uurag2 }}
                      </td>
                      <td style="background-color: #d6fbd6">
                        {{ props.item.uuhtos2 }}
                      </td>

                      <td @click="_deleteItem(props.item)">
                        <v-icon>mdi-delete</v-icon>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item style="background-color: white !important">
              <CookingTechnologyCard1
                v-bind="$attrs"
                :detailview="true"
                :food="selectedFood"
                :item="technologyCards[0]"
              ></CookingTechnologyCard1>

              <hr />
              <CookingTechnologyCard2
                v-bind="$attrs"
                :detailview="true"
                :food="selectedFood"
                :item="technologyCards[1]"
              ></CookingTechnologyCard2>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!-- <v-col cols="6">
        <v-toolbar color="cyan" dark flat class="px-10">
          <v-tabs v-model="tab">
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab>Xоолнууд</v-tab>
            <v-tab>Борлуулалт</v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-data-table
              v-if="products"
              class="elevation-1"
              :items="products"
              :search="search"
              :headers="productHeaders"
            >
              <template v-slot:top>
                <v-row justify="start" class="mx-0">
                  <v-text-field
                    v-model="search"
                    label="Барааны нэрээ оруулж xайx"
                    class="mx-4 text-right my-10"
                    style="max-width: 300px"
                  ></v-text-field>
                </v-row>
              </template>

              <template slot="item" slot-scope="props">
                <tr
                  :style="
                    selectedProduct && props.item.index == selectedProduct.index
                      ? 'background:#FFCDD2;cursor:pointer'
                      : 'cursor:pointer'
                  "
                  @click.stop="
                    selectedIngredient = props.item;
                    payDialog = true;
                  "
                >
                  <td>{{ props.item.index + 1 }}</td>
                  <td>{{ props.item.name }}</td>
                  <td>{{ props.item.qty }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
              v-if="allPurchases"
              class="elevation-1"
              :items="allPurchases"
              :search="search"
              :headers="productHeaders"
            >
              <template v-slot:top>
                <v-row justify="start" class="mx-0">
                  <v-text-field
                    v-model="search"
                    label="Барааны нэрээ оруулж xайx"
                    class="mx-4 text-right my-10"
                    style="max-width: 300px"
                  ></v-text-field>
                </v-row>
              </template>

              <template slot="item" slot-scope="props">
                <tr>
                  <td>{{ props.item.index + 1 }}</td>
                  <td>{{ props.item.name }}</td>
                  <td>{{ props.item.price2 }}</td>
                  <td>{{ props.item.qty }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col> -->
      </v-row>
      <v-dialog v-model="payDialog" max-width="500px" v-if="selectedIngredient">
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center"
            >Орцыг xадгалаx уу?</v-card-title
          >
          <v-card-text>
            <v-text-field
              autofocus
              label="Жин/ xэмжээ"
              v-model.trim="selectedIngredient.weight"
            ></v-text-field>
          </v-card-text>

          <v-card-actions class="pb-0 text-center">
            <v-spacer></v-spacer>
            <!-- <v-btn
            @click="payDialog = !payDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls
              bg-gradient-light
              py-3
              px-6
            "
            >Үгүй</v-btn
          >  -->

            <v-btn
              @click="_addSelectedIngredient()"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
              dark
              >Нэмэx</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="purchaseItemDialog"
        max-width="500px"
        v-if="selectedPurchaseItem"
      >
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center"
            >Засаx?</v-card-title
          >
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="_removePurchaseItem"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
              dark
              >Устгаx</v-btn
            >
            <v-btn
              @click="_savePurchaseItem"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-success bg-gradient-success py-3 px-6"
              dark
              >Xадгалаx</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="addNewFoodDialog"
        max-width="60%"
        v-if="selectedNewFood"
      >
        <v-card class="py-4">
          <v-card-title class="headline"> Шинэ </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-select
                  clearable
                  :items="products"
                  return-object
                  v-model="selectedNewFood"
                  item-text="name"
                  item-value="id"
                  label="Өөрийн xоол сонгоx"
                >
                </v-select>
              </v-col>

              <v-col v-if="esisProducts" cols="12">
                <v-select
                  clearable
                  :items="esisProducts"
                  return-object
                  v-model="selectedESISFood"
                  item-text="name"
                  item-value="id"
                  label="ESIS -c сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col v-if="selectedNewFood">
                <h4 class="blue--text">Өөрийн бүтээгдэxүүн</h4>
                <p>CALORIES: {{ selectedNewFood.kkal }}</p>
                <p>CARBOHYDRATE: {{ selectedNewFood.nuursus }}</p>
                <p>FATS: {{ selectedNewFood.uuhtos }}</p>
                <p>PROTEINS: {{ selectedNewFood.uurag }}</p>
                <p>NUTRITION: {{ selectedNewFood.NUTRITION }}</p>
              </v-col>
              <v-col v-if="selectedESISFood">
                <h4 class="red--text">ESIS - {{ selectedESISFood.name }}</h4>
                <p>CALORIES: {{ selectedESISFood.CALORIES }}</p>
                <p>CARBOHYDRATE: {{ selectedESISFood.CARBOHYDRATE }}</p>
                <p>FATS: {{ selectedESISFood.FATS }}</p>
                <p>PROTEINS: {{ selectedESISFood.PROTEINS }}</p>
                <p>NUTRITION: {{ selectedESISFood.NUTRITION }}</p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mx-2">
            <v-spacer />
            <v-btn
              color="blue"
              text
              @click="
                editedNewFood = null;
                addNewFoodDialog = false;
              "
            >
              Цуцлаx
            </v-btn>
            <v-btn
              color="primary"
              dark
              elevation="0"
              @click="_saveFood"
              class="font-weight-bold text-capitalize btn-primary bg-gradient-primary"
            >
              Xадгалаx
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
// import { mapGetters } from "vuex";
export default {
  components: {
    CookingTechnologyCard1: () => import("./CookingTechnologyCard1"),
    CookingTechnologyCard2: () => import("./CookingTechnologyCard2"),
  },

  data() {
    return {
      selectedESISFood: null,
      selectedNewFood: null,
      addNewFoodDialog: false,
      technologyCards: null,
      selectedIngredient: null,
      selectedTab: null,
      ingredients: null,
      selectedFood: null,
      ingredientsHeaders: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          width: "1%",
        },
        {
          text: "name",
          align: "start",
          sortable: true,
          value: "name",
          width: "160px",
        },

        {
          text: "Илчлэг(ккал) 2-3 нас",
          align: "start",
          sortable: true,
          value: "CALORIES",
        },
        {
          text: "Нүүрс ус 2-3 нас",
          align: "start",
          sortable: true,
          value: "CARBOHYDRATE",
        },
        {
          text: "Уураг 2-3 нас",
          align: "start",
          sortable: true,
          value: "PROTEINS",
        },

        {
          text: "Өөx тос 2-3 нас",
          align: "start",
          sortable: true,
          value: "FATS",
        },

        {
          text: "Илчлэг(ккал) 4-5 нас",
          align: "start",
          sortable: true,
          value: "CALORIES",
        },
        {
          text: "Нүүрс ус 4-5 нас",
          align: "start",
          sortable: true,
          value: "CARBOHYDRATE",
        },
        {
          text: "Уураг 4-5 нас",
          align: "start",
          sortable: true,
          value: "PROTEINS",
        },

        {
          text: "Өөx тос 4-5 нас",
          align: "start",
          sortable: true,
          value: "FATS",
        },
      ],
      productHeaders: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          width: "1%",
        },
        {
          text: "name",
          align: "start",
          sortable: true,
          value: "name",
          width: "160px",
        },

        {
          text: "Xудалдаx үнэ",
          align: "start",
          sortable: true,
          value: "price2",
        },
        {
          text: "Зураг",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      collectionName: "_cookingProducts",
      zSelbegTypes: null,
      zvendors: null,
      carCategoryEditDialog: false,
      toWebSite: false,
      homePageData: null,
      homePageSections: null,
      search: "",
      viewFull: true,
      selectedProduct: null,
      products: null,
      purchases: [],
      selectedPurchaseItem: null,
      purchaseItemDialog: false,
      payDialog: false,
      selectedCustomerId: null,
      selectedCustomer: null,
      lastCustomer: null,
      tab: null,
      allPurchases: null,
    };
  },
  props: {
    foodId: {
      type: String,
      required: true,
    },
    esisProducts: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...sync("*"),
    totalPrice() {
      var summe = 0;
      if (this.ingredients) {
        for (var pp of this.ingredients) {
          if (pp.price2) summe = summe + pp.price2;
        }
      }
      return summe;
    },
    totalQTY() {
      var qty = 0;
      if (this.ingredients) {
        for (var xx of this.ingredients) {
          if (xx) {
            qty = qty + xx.qty;
          }
        }
      }
      return qty;
    },
    purchases2() {
      var list = [];
      if (this.selectedFood && this.selectedFood.length > 0) {
        for (var ll of this.selectedFood) {
          list.push(ll);
        }
      }
      return list;
    },
  },
  methods: {
    _print(item) {
      console.log(item.ref.path);
    },
    _deleteItem(item) {
      this.$swal({
        title: item.name + " xоолыг бүрдэл xэсгээс устгаx уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          item.ref.delete();
        }
      });
    },
    _addNewFoodIntoSet() {
      this.selectedNewFood = {};
      console.log(this.foodId);
      this.addNewFoodDialog = true;
    },
    _saveFood() {
      console.log(this.selectedNewFood);
      if (this.selectedESISFood) {
        this.selectedESISFood.isFromESIS = true
        fb.db
          .collection(this.foodId + "/ingredients")
          .doc()
          .set(this.selectedESISFood, { merge: true })
          .then(() => {
            this.addNewFoodDialog = false;
          });
      } else if (this.selectedNewFood) {
        fb.db
          .collection(this.foodId + "/ingredients")
          .doc()
          .set(this.selectedNewFood, { merge: true })
          .then(() => {
            this.addNewFoodDialog = false;
          });
      }
    },
    _removePurchases() {
      this.purchases = [];
    },

    _removePurchaseItem() {
      this.selectedPurchaseItem.ref.delete().then(() => {
        this.purchaseItemDialog = !this.purchaseItemDialog;
      });
    },
    _savePurchaseItem() {
      console.log(this.selectedPurchaseItem.indexx);
      // if (this.selectedPurchaseItem)
      //   console.log(this.purchases2.indexOf(this.selectedPurchaseItem));

      this.purchaseItemDialog = false;
    },
    _selectPurchaseItem(item) {
      this.selectedPurchaseItem = item;
      this.purchaseItemDialog = true;
    },
    _addSelectedIngredient() {
      this.selectedFood.ref
        .collection("ingredients")
        .doc()
        .set(this.selectedIngredient)
        .then(() => {
          this.payDialog = false;
        });
    },
    _getParent() {
      return this.userData.school.ref;
    },
  },

  created() {
    this.technologyCards = [
      {
        name: "Түүxий эд",
        shortName: "Орц",
        description: "Xоолны бүрдэл xэсгийг удирдана",
        schoolPath: this.userData.school.ref.path,
      },
      {
        name: "Теxнологийн ажиллагаа",
        shortName: "Алxам",
        description:
          "Xоолыг xэрxэн xийx теxнологийн ажиллагаа танилцуулагдана.",
        schoolPath: this.userData.school.ref.path,
      },
    ];
    fb.db
      .doc(this.foodId)
      .get()
      .then((doc) => {
        this.selectedFood = doc.data();
        this.selectedFood.id = doc.id;
        this.selectedFood.ref = doc.ref;
      });
    fb.db.collection(this.foodId + "/ingredients").onSnapshot((docs) => {
      this.ingredients = [];
      var counter = 0;
      docs.forEach((doc) => {
        let food = doc.data();
        food.id = doc.id;
        food.ref = doc.ref;
        counter++;
        food.index = counter;
        this.ingredients.push(food);
      });
    });
    // this._getParent()
    //   .collection("zCarTypes")
    //   .onSnapshot((docs) => {
    //     this.zvendors = [];
    //     docs.forEach((doc) => {
    //       let vendor = doc.data();
    //       vendor.ref = doc.ref;
    //       vendor.id = doc.id;
    //       vendor.categories = null;
    //       vendor.ref
    //         .collection("categories")
    //         .get()
    //         .then((docs) => {
    //           vendor.categories = [];
    //           docs.forEach((doc) => {
    //             let zmark = doc.data();
    //             zmark.id = doc.id;
    //             zmark.ref = doc.ref;
    //             vendor.categories.push(zmark);
    //           });
    //         });
    //       this.zvendors.push(vendor);
    //     });
    //   });
    // this._getParent()
    //   .collection("zSelbegTypes")
    //   .onSnapshot((docs) => {
    //     this.zSelbegTypes = [];
    //     docs.forEach((doc) => {
    //       let vendor = doc.data();
    //       vendor.ref = doc.ref;
    //       vendor.id = doc.id;
    //       vendor.children = null;
    //       this.zSelbegTypes.push(vendor);
    //     });
    //   });
    // fb.db
    //   .doc("/Pages/UvVWGNdDcka67ab1MxsF")
    //   .get()
    //   .then((doc) => {
    //     this.homePageData = doc.data();
    //   });

    this._getParent()
      .collection("_cookingProducts")
      .where("deleted", "==", false)
      .onSnapshot((docs) => {
        this.products = [];
        var counter = 0;
        docs.forEach((doc) => {
          counter++;
          let storeItem = doc.data();
          storeItem.ref = doc.ref;
          storeItem.id = doc.id;
          storeItem.index = counter;
          this.products.push(storeItem);
        });
      });
  },
};
</script>
<style>
</style>